import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import 'bootstrap';
import '@/index/assets/js/flexslider.min.js';

import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';
import '@/index/assets/js/scripts.js';

jQueryBridget('masonry', Masonry, $);

const app = createApp(App);
app.config.globalProperties.getImage = (fileName) => require(fileName);
app.use(router).mount('#app');
