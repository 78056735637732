let mr_firstSectionHeight;
let mr_nav;
let mr_fixedAt;
let mr_navOuterHeight;
let mr_navScrolled = false;
let mr_navFixed = false;
let mr_outOfSight = false;
let mr_floatingProjectSections;
let mr_scrollTop = 0;

$(document).ready(() => {
  // Update scroll variable for scrolling functions

  addEventListener('scroll', () => {
    mr_scrollTop = window.pageYOffset;
  }, false);

  // Append .background-image-holder <img>'s as CSS backgrounds

  $('.background-image-holder').each(function () {
    const imgSrc = $(this).children('img').attr('src');
    $(this).css('background', `url("${imgSrc}")`);
    $(this).children('img').hide();
    $(this).css('background-position', 'initial');
  });

  // Fade in background images

  setTimeout(() => {
    $('.background-image-holder').each(function () {
      $(this).addClass('fadeIn');
    });
  }, 200);

  // Initialize Tooltips

  $('[data-toggle="tooltip"]').tooltip();

  // Icon bulleted lists

  $('ul[data-bullet]').each(function () {
    const bullet = $(this).attr('data-bullet');
    $(this).find('li').prepend(`<i class="${bullet}"></i>`);
  });

  // Progress Bars

  $('.progress-bar').each(function () {
    $(this).css('width', `${$(this).attr('data-progress')}%`);
  });

  // Navigation

  if (!$('nav').hasClass('fixed') && !$('nav').hasClass('absolute')) {
    // Make nav container height of nav

    $('.nav-container').css('min-height', $('nav').outerHeight(true));

    $(window).resize(() => {
      $('.nav-container').css('min-height', $('nav').outerHeight(true));
    });

    // Compensate the height of parallax element for inline nav

    if ($(window).width() > 768) {
      $('.parallax:nth-of-type(1) .background-image-holder').css('top', -($('nav').outerHeight(true)));
    }

    // Adjust fullscreen elements

    if ($(window).width() > 768) {
      $('section.fullscreen:nth-of-type(1)').css('height', ($(window).height() - $('nav').outerHeight(true)));
    }
  } else {
    $('body').addClass('nav-is-overlay');
  }

  if ($('nav').hasClass('bg-dark')) {
    $('.nav-container').addClass('bg-dark');
  }

  // Fix nav to top while scrolling

  mr_nav = $('body .nav-container nav:first');
  mr_navOuterHeight = $('body .nav-container nav:first').outerHeight();
  mr_fixedAt = typeof mr_nav.attr('data-fixed-at') !== typeof undefined ? parseInt(mr_nav.attr('data-fixed-at').replace('px', '')) : parseInt($('section:nth-of-type(1)').outerHeight());
  window.addEventListener('scroll', updateNav, false);

  // Menu dropdown positioning

  $('.menu > li > ul').each(function () {
    const menu = $(this).offset();
    const farRight = menu.left + $(this).outerWidth(true);
    if (farRight > $(window).width() && !$(this).hasClass('mega-menu')) {
      $(this).addClass('make-right');
    } else if (farRight > $(window).width() && $(this).hasClass('mega-menu')) {
      const isOnScreen = $(window).width() - menu.left;
      const difference = $(this).outerWidth(true) - isOnScreen;
      $(this).css('margin-left', -(difference));
    }
  });

  // Mobile Menu

  $('.mobile-toggle').click(function () {
    $('.nav-bar').toggleClass('nav-open');
    $(this).toggleClass('active');
  });

  $('.menu li').click(function (e) {
    if (!e) e = window.event;
    e.stopPropagation();
    if ($(this).find('ul').length) {
      $(this).toggleClass('toggle-sub');
    } else {
      $(this).parents('.toggle-sub').removeClass('toggle-sub');
    }
  });

  $('.menu li a').click(function () {
    if ($(this).hasClass('inner-link')) {
      $(this).closest('.nav-bar').removeClass('nav-open');
    }
  });

  $('.module.widget-handle').click(function () {
    $(this).toggleClass('toggle-widget-handle');
  });

  $('.search-widget-handle .search-form input').click((e) => {
    if (!e) e = window.event;
    e.stopPropagation();
  });

  // Offscreen Nav

  if ($('.offscreen-toggle').length) {
    	$('body').addClass('has-offscreen-nav');
  } else {
    $('body').removeClass('has-offscreen-nav');
  }

  $('.offscreen-toggle').click(() => {
    	$('.main-container').toggleClass('reveal-nav');
    	$('nav').toggleClass('reveal-nav');
    	$('.offscreen-container').toggleClass('reveal-nav');
  });

  $('.main-container').click(function () {
    	if ($(this).hasClass('reveal-nav')) {
    		$(this).removeClass('reveal-nav');
    		$('.offscreen-container').removeClass('reveal-nav');
    		$('nav').removeClass('reveal-nav');
    	}
  });

  $('.offscreen-container a').click(() => {
    	$('.offscreen-container').removeClass('reveal-nav');
    	$('.main-container').removeClass('reveal-nav');
    	$('nav').removeClass('reveal-nav');
  });

  // Populate filters

  $('.projects').each(function () {
    let filters = '';

    $(this).find('.project').each(function () {
      const filterTags = $(this).attr('data-filter').split(',');

      filterTags.forEach((tagName) => {
        if (filters.indexOf(tagName) == -1) {
          filters += `<li data-filter="${tagName}">${capitaliseFirstLetter(tagName)}</li>`;
        }
      });
      $(this).closest('.projects')
        .find('ul.filters').empty()
        .append('<li data-filter="all" class="active">All</li>')
        .append(filters);
    });
  });

  $('.filters li').click(function () {
    const filter = $(this).attr('data-filter');
    $(this).closest('.filters').find('li').removeClass('active');
    $(this).addClass('active');

    $(this).closest('.projects').find('.project').each(function () {
      const filters = $(this).attr('data-filter');

      if (filters.indexOf(filter) == -1) {
        $(this).addClass('inactive');
      } else {
        $(this).removeClass('inactive');
      }
    });

    if (filter == 'all') {
      $(this).closest('.projects').find('.project').removeClass('inactive');
    }
  });

  // Lightbox gallery titles

  $('.lightbox-grid li a').each(function () {
    	const galleryTitle = $(this).closest('.lightbox-grid').attr('data-gallery-title');
    	$(this).attr('data-lightbox', galleryTitle);
  });

  // Prepare embedded video modals

  $('iframe[data-provider]').each(function () {
    const provider = jQuery(this).attr('data-provider');
    const videoID = jQuery(this).attr('data-video-id');
    const autoplay = jQuery(this).attr('data-autoplay');
    let vidURL = '';

    if (provider == 'vimeo') {
      vidURL = `http://player.vimeo.com/video/${videoID}?badge=0&title=0&byline=0&title=0&autoplay=${autoplay}`;
      $(this).attr('data-src', vidURL);
    } else if (provider == 'youtube') {
      vidURL = `https://www.youtube.com/embed/${videoID}?showinfo=0&autoplay=${autoplay}`;
      $(this).attr('data-src', vidURL);
    } else {
      console.log('Only Vimeo and Youtube videos are supported at this time');
    }
  });

  // Multipurpose Modals

  jQuery('.foundry_modal[modal-link]').remove();

  if ($('.foundry_modal').length && (!jQuery('.modal-screen').length)) {
    // Add a div.modal-screen if there isn't already one there.
    const modalScreen = jQuery('<div />').addClass('modal-screen').appendTo('body');
  }

  jQuery('.foundry_modal').click(function () {
    jQuery(this).addClass('modal-acknowledged');
  });

  jQuery(document).on('wheel mousewheel scroll', '.foundry_modal, .modal-screen', function (evt) {
    $(this).get(0).scrollTop += (evt.originalEvent.deltaY);
    return false;
  });

  $('.modal-container:not([modal-link])').each(function (index) {
    if (jQuery(this).find('iframe[src]').length) {
        	jQuery(this).find('.foundry_modal').addClass('iframe-modal');
        	const iframe = jQuery(this).find('iframe');
        	iframe.attr('data-src', iframe.attr('src'));
      iframe.attr('src', '');
    }
    jQuery(this).find('.btn-modal').attr('modal-link', index);

    // Only clone and append to body if there isn't already one there
    if (!jQuery(`.foundry_modal[modal-link="${index}"]`).length) {
      jQuery(this).find('.foundry_modal').clone().appendTo('body')
        .attr('modal-link', index)
        .prepend(jQuery('<i class="ti-close close-modal">'));
    }
  });

  $('.btn-modal').unbind('click').click(function () {
    	const linkedModal = jQuery(`.foundry_modal[modal-link="${jQuery(this).attr('modal-link')}"]`);
    var autoplayMsg = '';
    jQuery('.modal-screen').toggleClass('reveal-modal');
    if (linkedModal.find('iframe').length) {
      if (linkedModal.find('iframe').attr('data-autoplay') === '1') {
        var autoplayMsg = '&autoplay=1';
      }
        	linkedModal.find('iframe').attr('src', (linkedModal.find('iframe').attr('data-src') + autoplayMsg));
    }
    if (linkedModal.find('video').length) {
      linkedModal.find('video').get(0).play();
    }
    linkedModal.toggleClass('reveal-modal');
    return false;
  });

  // Autoshow modals

  $('.foundry_modal[data-time-delay]').each(function () {
    const modal = $(this);
    const delay = modal.attr('data-time-delay');
    modal.prepend($('<i class="ti-close close-modal">'));
    	if (typeof modal.attr('data-cookie') !== 'undefined') {
        	if (!mr_cookies.hasItem(modal.attr('data-cookie'))) {
        setTimeout(() => {
        			modal.addClass('reveal-modal');
        			$('.modal-screen').addClass('reveal-modal');
        		}, delay);
      }
    } else {
      setTimeout(() => {
        modal.addClass('reveal-modal');
        $('.modal-screen').addClass('reveal-modal');
      }, delay);
    }
  });

  // Exit modals
  $('.foundry_modal[data-show-on-exit]').each(function () {
    const modal = $(this);
    const exitSelector = $(modal.attr('data-show-on-exit'));
    // If a valid selector is found, attach leave event to show modal.
    if ($(exitSelector).length) {
      modal.prepend($('<i class="ti-close close-modal">'));
      $(document).on('mouseleave', exitSelector, () => {
        if (!$('body .reveal-modal').length) {
          if (typeof modal.attr('data-cookie') !== typeof undefined) {
            if (!mr_cookies.hasItem(modal.attr('data-cookie'))) {
              modal.addClass('reveal-modal');
              $('.modal-screen').addClass('reveal-modal');
            }
          } else {
            modal.addClass('reveal-modal');
            $('.modal-screen').addClass('reveal-modal');
          }
        }
      });
    }
  });

  // Autoclose modals

  $('.foundry_modal[data-hide-after]').each(function () {
    const modal = $(this);
    const delay = modal.attr('data-hide-after');
    if (typeof modal.attr('data-cookie') !== 'undefined') {
      if (!mr_cookies.hasItem(modal.attr('data-cookie'))) {
        setTimeout(() => {
          if (!modal.hasClass('modal-acknowledged')) {
            modal.removeClass('reveal-modal');
            $('.modal-screen').removeClass('reveal-modal');
          }
        }, delay);
      }
    } else {
      setTimeout(() => {
        if (!modal.hasClass('modal-acknowledged')) {
          modal.removeClass('reveal-modal');
          $('.modal-screen').removeClass('reveal-modal');
        }
      }, delay);
    }
  });

  jQuery('.close-modal:not(.modal-strip .close-modal)').unbind('click').click(function () {
    	const modal = jQuery(this).closest('.foundry_modal');
    modal.toggleClass('reveal-modal');
    if (typeof modal.attr('data-cookie') !== 'undefined') {
      mr_cookies.setItem(modal.attr('data-cookie'), 'true', Infinity);
    }
    	if (modal.find('iframe').length) {
      modal.find('iframe').attr('src', '');
    }
    jQuery('.modal-screen').removeClass('reveal-modal');
  });

  jQuery('.modal-screen').unbind('click').click(function () {
    if (jQuery('.foundry_modal.reveal-modal').find('iframe').length) {
      jQuery('.foundry_modal.reveal-modal').find('iframe').attr('src', '');
    }
    	jQuery('.foundry_modal.reveal-modal').toggleClass('reveal-modal');
    	jQuery(this).toggleClass('reveal-modal');
  });

  jQuery(document).keyup((e) => {
		 if (e.keyCode == 27) { // escape key maps to keycode `27`
      if (jQuery('.foundry_modal').find('iframe').length) {
        jQuery('.foundry_modal').find('iframe').attr('src', '');
      }
      jQuery('.foundry_modal').removeClass('reveal-modal');
      jQuery('.modal-screen').removeClass('reveal-modal');
    }
  });

  // Modal Strips

  jQuery('.modal-strip').each(function () {
    	if (!jQuery(this).find('.close-modal').length) {
    		jQuery(this).append(jQuery('<i class="ti-close close-modal">'));
    	}
    	const modal = jQuery(this);

    if (typeof modal.attr('data-cookie') !== 'undefined') {
      if (!mr_cookies.hasItem(modal.attr('data-cookie'))) {
            	setTimeout(() => {
            		modal.addClass('reveal-modal');
            	}, 1000);
      }
    } else {
      setTimeout(() => {
        modal.addClass('reveal-modal');
      }, 1000);
    }
  });

  jQuery('.modal-strip .close-modal').click(function () {
    const modal = jQuery(this).closest('.modal-strip');
    if (typeof modal.attr('data-cookie') !== 'undefined') {
      mr_cookies.setItem(modal.attr('data-cookie'), 'true', Infinity);
    }
    	jQuery(this).closest('.modal-strip').removeClass('reveal-modal');
    	return false;
  });

  // Video Modals

  jQuery('.close-iframe').click(function () {
    jQuery(this).closest('.modal-video').removeClass('reveal-modal');
    jQuery(this).siblings('iframe').attr('src', '');
    jQuery(this).siblings('video').get(0).pause();
  });

  // Checkboxes

  $('.checkbox-option').on('click', function () {
    $(this).toggleClass('checked');
    const checkbox = $(this).find('input');
    if (checkbox.prop('checked') === false) {
      checkbox.prop('checked', true);
    } else {
      checkbox.prop('checked', false);
    }
  });

  // Radio Buttons

  $('.radio-option').click(function () {
    const checked = $(this).hasClass('checked'); // Get the current status of the radio

    const name = $(this).find('input').attr('name'); // Get the name of the input clicked

    if (!checked) {
      $(`input[name="${name}"]`).parent().removeClass('checked');

      $(this).addClass('checked');

      $(this).find('input').prop('checked', true);
    }
  });

  // Accordions

  $('.accordion li').click(function () {
    if ($(this).closest('.accordion').hasClass('one-open')) {
      $(this).closest('.accordion').find('li').removeClass('active');
      $(this).addClass('active');
    } else {
      $(this).toggleClass('active');
    }
    if (typeof window.mr_parallax !== 'undefined') {
      setTimeout(mr_parallax.windowLoad, 500);
    }
  });

  // Tabbed Content

  $('.tabbed-content').each(function () {
    $(this).append('<ul class="content"></ul>');
  });

  $('.tabs li').each(function () {
    const originalTab = $(this);
    let activeClass = '';
    if (originalTab.is('.tabs>li:first-child')) {
      activeClass = ' class="active"';
    }
    const tabContent = originalTab.find('.tab-content').detach().wrap(`<li${activeClass}></li>`).parent();
    originalTab.closest('.tabbed-content').find('.content').append(tabContent);
  });

  $('.tabs li').click(function () {
    $(this).closest('.tabs').find('li').removeClass('active');
    $(this).addClass('active');
    const liIndex = $(this).index() + 1;
    $(this).closest('.tabbed-content').find('.content>li').removeClass('active');
    $(this).closest('.tabbed-content').find(`.content>li:nth-of-type(${liIndex})`).addClass('active');
  });

  // Local Videos

  $('section').closest('body').find('.local-video-container .play-button').click(function () {
    $(this).siblings('.background-image-holder').removeClass('fadeIn');
    $(this).siblings('.background-image-holder').css('z-index', -1);
    $(this).css('opacity', 0);
    $(this).siblings('video').get(0).play();
  });

  // Youtube Videos

  $('section').closest('body').find('.player').each(function () {
    const section = $(this).closest('section');
    section.find('.container').addClass('fadeOut');
    const src = $(this).attr('data-video-id');
    const startat = $(this).attr('data-start-at');
    $(this).attr('data-property', `{videoURL:'http://youtu.be/${src}',containment:'self',autoPlay:true, mute:true, startAt:${startat}, opacity:1, showControls:false}`);
  });

  if ($('.player').length) {
    $('.player').each(function () {
      const section = $(this).closest('section');
      const player = section.find('.player');
      player.YTPlayer();
      player.on('YTPStart', (e) => {
        section.find('.container').removeClass('fadeOut');
        section.find('.masonry-loader').addClass('fadeOut');
      });
    });
  }

  // Interact with Map once the user has clicked (to prevent scrolling the page = zooming the map

  $('.map-holder').click(function () {
    $(this).addClass('interact');
  });

  if ($('.map-holder').length) {
    	$(window).scroll(() => {
      if ($('.map-holder.interact').length) {
        $('.map-holder.interact').removeClass('interact');
      }
    });
  }

  // Countdown Timers

  if ($('.countdown').length) {
    $('.countdown').each(function () {
      const date = $(this).attr('data-date');
      $(this).countdown(date, function (event) {
        $(this).text(
          event.strftime('%D days %H:%M:%S'),
        );
      });
    });
  }

  //                                                            //
  //                                                            //
  // Contact form code                                          //
  //                                                            //
  //                                                            //

  $('form.form-email, form.form-newsletter').submit(function (e) {
    // return false so form submits through jQuery rather than reloading page.
    if (e.preventDefault) e.preventDefault();
    else e.returnValue = false;

    const thisForm = $(this).closest('form.form-email, form.form-newsletter');
    const submitButton = thisForm.find('button[type="submit"]');
    let error = 0;
    const originalError = thisForm.attr('original-error');
    let preparedForm; let iFrame; let userEmail; let userFullName; let userFirstName; let userLastName; let successRedirect; let formError; let
      formSuccess;

    // Mailchimp/Campaign Monitor Mail List Form Scripts
    iFrame = $(thisForm).find('iframe.mail-list-form');

    thisForm.find('.form-error, .form-success').remove();
    submitButton.attr('data-text', submitButton.text());
    thisForm.append(`<div class="form-error" style="display: none;">${thisForm.attr('data-error')}</div>`);
    thisForm.append(`<div class="form-success" style="display: none;">${thisForm.attr('data-success')}</div>`);
    formError = thisForm.find('.form-error');
    formSuccess = thisForm.find('.form-success');
    thisForm.addClass('attempted-submit');

    // Do this if there is an iframe, and it contains usable Mail Chimp / Campaign Monitor iframe embed code
    if ((iFrame.length) && (typeof iFrame.attr('srcdoc') !== 'undefined') && (iFrame.attr('srcdoc') !== '')) {
      console.log('Mail list form signup detected.');
      if (typeof originalError !== typeof undefined && originalError !== false) {
        formError.html(originalError);
      }
      userEmail = $(thisForm).find('.signup-email-field').val();
      userFullName = $(thisForm).find('.signup-name-field').val();
      if ($(thisForm).find('input.signup-first-name-field').length) {
        userFirstName = $(thisForm).find('input.signup-first-name-field').val();
      } else {
        userFirstName = $(thisForm).find('.signup-name-field').val();
      }
      userLastName = $(thisForm).find('.signup-last-name-field').val();

      // validateFields returns 1 on error;
      if (validateFields(thisForm) !== 1) {
        preparedForm = prepareSignup(iFrame);

        preparedForm.find('#mce-EMAIL, #fieldEmail').val(userEmail);
        preparedForm.find('#mce-LNAME, #fieldLastName').val(userLastName);
        preparedForm.find('#mce-FNAME, #fieldFirstName').val(userFirstName);
        preparedForm.find('#mce-NAME, #fieldName').val(userFullName);
        thisForm.removeClass('attempted-submit');

        // Hide the error if one was shown
        formError.fadeOut(200);
        // Create a new loading spinner in the submit button.
        submitButton.html(jQuery('<div />').addClass('form-loading')).attr('disabled', 'disabled');

        try {
          $.ajax({
            url: preparedForm.attr('action'),
            crossDomain: true,
            data: preparedForm.serialize(),
            method: 'GET',
            cache: false,
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
            success(data) {
              // Request was a success, what was the response?
              if (data.result != 'success' && data.Status != 200) {
                // Error from Mail Chimp or Campaign Monitor

                // Keep the current error text in a data attribute on the form
                formError.attr('original-error', formError.text());
                // Show the error with the returned error text.
                formError.html(data.msg).fadeIn(1000);
                formSuccess.fadeOut(1000);

                submitButton.html(submitButton.attr('data-text')).removeAttr('disabled');
              } else {
                // Got Success from Mail Chimp

                submitButton.html(submitButton.attr('data-text')).removeAttr('disabled');

                successRedirect = thisForm.attr('success-redirect');
                // For some browsers, if empty `successRedirect` is undefined; for others,
                // `successRedirect` is false.  Check for both.
                if (typeof successRedirect !== typeof undefined && successRedirect !== false && successRedirect !== '') {
                  window.location = successRedirect;
                }

                thisForm.find('input[type="text"]').val('');
                thisForm.find('textarea').val('');
                formSuccess.fadeIn(1000);

                formError.fadeOut(1000);
                setTimeout(() => {
                  formSuccess.fadeOut(500);
                }, 5000);
              }
            },
          });
        } catch (err) {
          // Keep the current error text in a data attribute on the form
          formError.attr('original-error', formError.text());
          // Show the error with the returned error text.
          formError.html(err.message).fadeIn(1000);
          formSuccess.fadeOut(1000);
          setTimeout(() => {
            formError.fadeOut(500);
          }, 5000);

          submitButton.html(submitButton.attr('data-text')).removeAttr('disabled');
        }
      } else {
        formError.fadeIn(1000);
        setTimeout(() => {
          formError.fadeOut(500);
        }, 5000);
      }
    } else {
      // If no iframe detected then this is treated as an email form instead.
      console.log('Send email form detected.');
      if (typeof originalError !== typeof undefined && originalError !== false) {
        formError.text(originalError);
      }

      error = validateFields(thisForm);

      if (error === 1) {
        formError.fadeIn(200);
        setTimeout(() => {
          formError.fadeOut(500);
        }, 3000);
      } else {
        thisForm.removeClass('attempted-submit');

        // Hide the error if one was shown
        formError.fadeOut(200);

        // Create a new loading spinner in the submit button.
        submitButton.html(jQuery('<div />').addClass('form-loading')).attr('disabled', 'disabled');

        jQuery.ajax({
          type: 'POST',
          url: 'mail/mail.php',
          data: `${thisForm.serialize()}&url=${window.location.href}`,
          success(response) {
            // Swiftmailer always sends back a number representing numner of emails sent.
            // If this is numeric (not Swift Mailer error text) AND greater than 0 then show success message.

            submitButton.html(submitButton.attr('data-text')).removeAttr('disabled');

            if ($.isNumeric(response)) {
              if (parseInt(response) > 0) {
                // For some browsers, if empty 'successRedirect' is undefined; for others,
                // 'successRedirect' is false.  Check for both.
                successRedirect = thisForm.attr('success-redirect');
                if (typeof successRedirect !== typeof undefined && successRedirect !== false && successRedirect !== '') {
                  window.location = successRedirect;
                }

                thisForm.find('input[type="text"]').val('');
                thisForm.find('textarea').val('');
                thisForm.find('.form-success').fadeIn(1000);

                formError.fadeOut(1000);
                setTimeout(() => {
                  formSuccess.fadeOut(500);
                }, 5000);
              }
            }
            // If error text was returned, put the text in the .form-error div and show it.
            else {
              // Keep the current error text in a data attribute on the form
              formError.attr('original-error', formError.text());
              // Show the error with the returned error text.
              formError.text(response).fadeIn(1000);
              formSuccess.fadeOut(1000);
            }
          },
          error(errorObject, errorText, errorHTTP) {
            // Keep the current error text in a data attribute on the form
            formError.attr('original-error', formError.text());
            // Show the error with the returned error text.
            formError.text(errorHTTP).fadeIn(1000);
            formSuccess.fadeOut(1000);
            submitButton.html(submitButton.attr('data-text')).removeAttr('disabled');
          },
        });
      }
    }
    return false;
  });

  $('.validate-required, .validate-email').on('blur change', function () {
    validateFields($(this).closest('form'));
  });

  $('form').each(function () {
    if ($(this).find('.form-error').length) {
      $(this).attr('original-error', $(this).find('.form-error').text());
    }
  });

  function validateFields(form) {
    let name; let error; let
      originalErrorMessage;

    $(form).find('.validate-required[type="checkbox"]').each(function () {
      if (!$(`[name="${$(this).attr('name')}"]:checked`).length) {
        error = 1;
        name = $(this).attr('name').replace('[]', '');
        form.find('.form-error').text(`Please tick at least one ${name} box.`);
      }
    });

    $(form).find('.validate-required').each(function () {
      if ($(this).val() === '') {
        $(this).addClass('field-error');
        error = 1;
      } else {
        $(this).removeClass('field-error');
      }
    });

    $(form).find('.validate-email').each(function () {
      if (!(/(.+)@(.+){2,}\.(.+){2,}/.test($(this).val()))) {
        $(this).addClass('field-error');
        error = 1;
      } else {
        $(this).removeClass('field-error');
      }
    });

    if (!form.find('.field-error').length) {
      form.find('.form-error').fadeOut(1000);
    }

    return error;
  }

  //
  //
  // End contact form code
  //
  //

  // Get referrer from URL string
  if (getURLParameter('ref')) {
    $('form.form-email').append(`<input type="text" name="referrer" class="hidden" value="${getURLParameter('ref')}"/>`);
  }

  function getURLParameter(name) {
    return decodeURIComponent((new RegExp(`[?|&]${name}=` + '([^&;]+?)(&|#|;|$)').exec(location.search) || [, ''])[1].replace(/\+/g, '%20')) || null;
  }

  // Disable parallax on mobile

  if ((/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera)) {
    $('section').removeClass('parallax');
  }

  // Disqus Comments

  if ($('.disqus-comments').length) {
    /* * * CONFIGURATION VARIABLES * * */
    const disqus_shortname = $('.disqus-comments').attr('data-shortname');

    /* * * DON'T EDIT BELOW THIS LINE * * */
    (function () {
      const dsq = document.createElement('script'); dsq.type = 'text/javascript'; dsq.async = true;
      dsq.src = `//${disqus_shortname}.disqus.com/embed.js`;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(dsq);
    }());
  }

  // Load Google MAP API JS with callback to initialise when fully loaded
  if (document.querySelector('[data-maps-api-key]') && !document.querySelector('.gMapsAPI')) {
    if ($('[data-maps-api-key]').length) {
      const script = document.createElement('script');
      const apiKey = $('[data-maps-api-key]:first').attr('data-maps-api-key');
      script.type = 'text/javascript';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initializeMaps`;
      script.className = 'gMapsAPI';
      document.body.appendChild(script);
    }
  }
});

function updateNav() {
  const scrollY = mr_scrollTop;

  if (scrollY <= 0) {
    if (mr_navFixed) {
      mr_navFixed = false;
      mr_nav.removeClass('fixed');
    }
    if (mr_outOfSight) {
      mr_outOfSight = false;
      mr_nav.removeClass('outOfSight');
    }
    if (mr_navScrolled) {
      mr_navScrolled = false;
      mr_nav.removeClass('scrolled');
    }
    return;
  }

  if (scrollY > mr_navOuterHeight + mr_fixedAt) {
    if (!mr_navScrolled) {
      mr_nav.addClass('scrolled');
      mr_navScrolled = true;
    }
  } else {
    if (scrollY > mr_navOuterHeight) {
      if (!mr_navFixed) {
        mr_nav.addClass('fixed');
        mr_navFixed = true;
      }

      if (scrollY > mr_navOuterHeight + 10) {
        if (!mr_outOfSight) {
          mr_nav.addClass('outOfSight');
          mr_outOfSight = true;
        }
      } else if (mr_outOfSight) {
        mr_outOfSight = false;
        mr_nav.removeClass('outOfSight');
      }
    } else {
      if (mr_navFixed) {
        mr_navFixed = false;
        mr_nav.removeClass('fixed');
      }
      if (mr_outOfSight) {
        mr_outOfSight = false;
        mr_nav.removeClass('outOfSight');
      }
    }

    if (mr_navScrolled) {
      mr_navScrolled = false;
      mr_nav.removeClass('scrolled');
    }
  }
}

function capitaliseFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function initializeMasonry() {
  $('.masonry').each(function () {
    const container = $(this).get(0);
    const msnry = new Masonry(container, {
      itemSelector: '.masonry-item',
    });

    msnry.on('layoutComplete', () => {
      mr_firstSectionHeight = $('.main-container section:nth-of-type(1)').outerHeight(true);

      // Fix floating project filters to bottom of projects container

      if ($('.filters.floating').length) {
        setupFloatingProjectFilters();
        updateFloatingFilters();
        window.addEventListener('scroll', updateFloatingFilters, false);
      }

      $('.masonry').addClass('fadeIn');
      $('.masonry-loader').addClass('fadeOut');
      if ($('.masonryFlyIn').length) {
        masonryFlyIn();
      }
    });

    msnry.layout();
  });
}

function masonryFlyIn() {
  const $items = $('.masonryFlyIn .masonry-item');
  let time = 0;

  $items.each(function () {
    const item = $(this);
    setTimeout(() => {
      item.addClass('fadeIn');
    }, time);
    time += 170;
  });
}

function setupFloatingProjectFilters() {
  mr_floatingProjectSections = [];
  $('.filters.floating').closest('section').each(function () {
    const section = $(this);

    mr_floatingProjectSections.push({
      section: section.get(0),
      outerHeight: section.outerHeight(),
      elemTop: section.offset().top,
      elemBottom: section.offset().top + section.outerHeight(),
      filters: section.find('.filters.floating'),
      filersHeight: section.find('.filters.floating').outerHeight(true),
    });
  });
}

function updateFloatingFilters() {
  let l = mr_floatingProjectSections.length;
  while (l--) {
    const section = mr_floatingProjectSections[l];

    if ((section.elemTop < mr_scrollTop) && typeof window.mr_variant === 'undefined') {
      section.filters.css({
        position: 'fixed',
        top: '16px',
        bottom: 'auto',
      });
      if (mr_navScrolled) {
        section.filters.css({
          transform: 'translate3d(-50%,48px,0)',
        });
      }
      if (mr_scrollTop > (section.elemBottom - 70)) {
        section.filters.css({
          position: 'absolute',
          bottom: '16px',
          top: 'auto',
        });
        section.filters.css({
          transform: 'translate3d(-50%,0,0)',
        });
      }
    } else {
      section.filters.css({
        position: 'absolute',
        transform: 'translate3d(-50%,0,0)',
      });
    }
  }
}

window.initializeMaps = function () {
  if (typeof google !== 'undefined') {
    if (typeof google.maps !== 'undefined') {
      $('.map-canvas[data-maps-api-key]').each(function () {
        const mapInstance = this;
        const mapJSON = typeof $(this).attr('data-map-style') !== 'undefined' ? $(this).attr('data-map-style') : false;
        const mapStyle = JSON.parse(mapJSON) || [{ featureType: 'landscape', stylers: [{ saturation: -100 }, { lightness: 65 }, { visibility: 'on' }] }, { featureType: 'poi', stylers: [{ saturation: -100 }, { lightness: 51 }, { visibility: 'simplified' }] }, { featureType: 'road.highway', stylers: [{ saturation: -100 }, { visibility: 'simplified' }] }, { featureType: 'road.arterial', stylers: [{ saturation: -100 }, { lightness: 30 }, { visibility: 'on' }] }, { featureType: 'road.local', stylers: [{ saturation: -100 }, { lightness: 40 }, { visibility: 'on' }] }, { featureType: 'transit', stylers: [{ saturation: -100 }, { visibility: 'simplified' }] }, { featureType: 'administrative.province', stylers: [{ visibility: 'off' }] }, { featureType: 'water', elementType: 'labels', stylers: [{ visibility: 'on' }, { lightness: -25 }, { saturation: -100 }] }, { featureType: 'water', elementType: 'geometry', stylers: [{ hue: '#ffff00' }, { lightness: -25 }, { saturation: -97 }] }];
        const zoomLevel = (typeof $(this).attr('data-map-zoom') !== 'undefined' && $(this).attr('data-map-zoom') !== '') ? $(this).attr('data-map-zoom') * 1 : 17;
        const latlong = typeof $(this).attr('data-latlong') !== 'undefined' ? $(this).attr('data-latlong') : false;
        const latitude = latlong ? 1 * latlong.substr(0, latlong.indexOf(',')) : false;
        const longitude = latlong ? 1 * latlong.substr(latlong.indexOf(',') + 1) : false;
        const geocoder = new google.maps.Geocoder();
        const address = typeof $(this).attr('data-address') !== 'undefined' ? $(this).attr('data-address').split(';') : [''];
        let markerTitle = 'We Are Here';
        const isDraggable = $(document).width() > 766;
        let map; let marker; let markerImage;
        const mapOptions = {
          draggable: isDraggable,
          scrollwheel: false,
          zoom: zoomLevel,
          disableDefaultUI: true,
          styles: mapStyle,
        };

        if ($(this).attr('data-marker-title') != undefined && $(this).attr('data-marker-title') != '') {
          markerTitle = $(this).attr('data-marker-title');
        }

        if (address != undefined && address[0] != '') {
          geocoder.geocode({ address: address[0].replace('[nomarker]', '') }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              const map = new google.maps.Map(mapInstance, mapOptions);
              map.setCenter(results[0].geometry.location);

              address.forEach((address) => {
                let markerGeoCoder;

                markerImage = { url: window.mr_variant == undefined ? 'img/mapmarker.png' : '../img/mapmarker.png', size: new google.maps.Size(50, 50), scaledSize: new google.maps.Size(50, 50) };
                if (/(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)/.test(address)) {
                  const latlong = address.split(',');
                  var marker = new google.maps.Marker({
                    position: { lat: 1 * latlong[0], lng: 1 * latlong[1] },
                    map,
                    icon: markerImage,
                    title: markerTitle,
                    optimised: false,
                  });
                } else if (address.indexOf('[nomarker]') < 0) {
                  markerGeoCoder = new google.maps.Geocoder();
                  markerGeoCoder.geocode({ address: address.replace('[nomarker]', '') }, (results, status) => {
                    if (status == google.maps.GeocoderStatus.OK) {
                      marker = new google.maps.Marker({
                        map,
                        icon: markerImage,
                        title: markerTitle,
                        position: results[0].geometry.location,
                        optimised: false,
                      });
                    }
                  });
                }
              });
            } else {
              console.log('There was a problem geocoding the address.');
            }
          });
        } else if (latitude != undefined && latitude != '' && latitude != false && longitude != undefined && longitude != '' && longitude != false) {
          mapOptions.center = { lat: latitude, lng: longitude };
          map = new google.maps.Map(mapInstance, mapOptions);
          marker = new google.maps.Marker({
            position: { lat: latitude, lng: longitude },
            map,
            icon: markerImage,
            title: markerTitle,
          });
        }
      });
    }
  }
};
initializeMaps();

// End of Maps

// Prepare Signup Form - It is used to retrieve form details from an iframe Mail Chimp or Campaign Monitor form.

function prepareSignup(iFrame) {
  const form = jQuery('<form />');
  const div = jQuery('<div />');
  let action;

  jQuery(div).html(iFrame.attr('srcdoc'));
  action = jQuery(div).find('form').attr('action');

  // Alter action for a Mail Chimp-compatible ajax request url.
  if (/list-manage\.com/.test(action)) {
    action = `${action.replace('/post?', '/post-json?')}&c=?`;
    if (action.substr(0, 2) == '//') {
      action = `http:${action}`;
    }
  }

  // Alter action for a Campaign Monitor-compatible ajax request url.
  if (/createsend\.com/.test(action)) {
    action += '?callback=?';
  }

  // Set action on the form
  form.attr('action', action);

  // Clone form input fields from
  jQuery(div).find('input, select, textarea').not('input[type="submit"]').each(function () {
    jQuery(this).clone().appendTo(form);
  });

  return form;
}

/* \
|*|  COOKIE LIBRARY THANKS TO MDN
|*|
|*|  A complete cookies reader/writer framework with full unicode support.
|*|
|*|  Revision #1 - September 4, 2014
|*|
|*|  https://developer.mozilla.org/en-US/docs/Web/API/document.cookie
|*|  https://developer.mozilla.org/User:fusionchess
|*|
|*|  This framework is released under the GNU Public License, version 3 or later.
|*|  http://www.gnu.org/licenses/gpl-3.0-standalone.html
|*|
|*|  Syntaxes:
|*|
|*|  * mr_cookies.setItem(name, value[, end[, path[, domain[, secure]]]])
|*|  * mr_cookies.getItem(name)
|*|  * mr_cookies.removeItem(name[, path[, domain]])
|*|  * mr_cookies.hasItem(name)
|*|  * mr_cookies.keys()
|*|
\ */

var mr_cookies = {
  getItem(sKey) {
    if (!sKey) { return null; }
    return decodeURIComponent(document.cookie.replace(new RegExp(`(?:(?:^|.*;)\\s*${encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&')}\\s*\\=\\s*([^;]*).*$)|^.*$`), '$1')) || null;
  },
  setItem(sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
    let sExpires = '';
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : `; max-age=${vEnd}`;
          break;
        case String:
          sExpires = `; expires=${vEnd}`;
          break;
        case Date:
          sExpires = `; expires=${vEnd.toUTCString()}`;
          break;
      }
    }
    document.cookie = `${encodeURIComponent(sKey)}=${encodeURIComponent(sValue)}${sExpires}${sDomain ? `; domain=${sDomain}` : ''}${sPath ? `; path=${sPath}` : ''}${bSecure ? '; secure' : ''}`;
    return true;
  },
  removeItem(sKey, sPath, sDomain) {
    if (!this.hasItem(sKey)) { return false; }
    document.cookie = `${encodeURIComponent(sKey)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${sDomain ? `; domain=${sDomain}` : ''}${sPath ? `; path=${sPath}` : ''}`;
    return true;
  },
  hasItem(sKey) {
    if (!sKey) { return false; }
    return (new RegExp(`(?:^|;\\s*)${encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&')}\\s*\\=`)).test(document.cookie);
  },
  keys() {
    const aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '').split(/\s*(?:\=[^;]*)?;\s*/);
    for (let nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
    return aKeys;
  },
};

/* \
|*|  END COOKIE LIBRARY
\ */
