<script>
export default {
  name: "Header",
};
</script>
<template>
  <div class="nav-container">
    <nav class="bg-dark">
      <div class="nav-bar">
        <div class="module left">
          <router-link to="/">
            <img src="@/index/assets/img/smg-logo-white.png" alt="" height="100">
            <!-- <span class="logo-name"> Stuecher Manning Group </span> -->
          </router-link>
        </div>
        <div
          class="module widget-handle mobile-toggle right visible-sm visible-xs"
        >
          <i class="ti-menu"></i>
        </div>
        <div class="module-group right">
          <div class="module left">
            <ul class="menu">
              <li>
                <router-link to="/contact-us">Contact Us</router-link>
              </li>
              <li class="has-dropdown">
                <a href="#">About Us</a>
                <ul>
                  <li><router-link to="/about-kristen">Meet Kristen</router-link></li>
                  <li><router-link to="/about-cindy">Meet Cindy</router-link></li>
                  <li><router-link to="/meet-team">Meet The Team</router-link></li>
                </ul>
              </li>
              <li>
                <a href="/blog">Press</a>
              </li>
              <li class="has-dropdown">
                <router-link to="/listings">Properties</router-link>
                <ul>
                  <li>
                    <a
                      href="/listings"
                    >
                      Featured Properties
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <router-link to="/market-trends">Market Trends</router-link>
              </li>
              <li>
                <router-link to="/testimonials">Testimonials</router-link>
              </li>
              <li>
                <a href="https://wy.stuechermanninggroup.com/area-guide">Wyoming Area Guide</a>
              </li>
              <li class="has-dropdown">
                <a href="#"> Compass Advantages </a>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.compass.com/concierge/stuechermanninggroup/"
                    >
                      Compass Concierge
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.compass.com/bridge-loan-services/stuechermanninggroup"
                    >
                      Bridge Loans
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.compass.com/private-exclusives/"
                    >
                      Private Exclusive
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.compass.com/coming-soon/"
                    >
                      Coming Soon
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.compass.com/c/kristen-stuecher/san-francisco-homes?agent_id=5e2f498d04d3012f1e01c9a0"
                    >
                      Collections
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.compass.com/calculators/mortgage-calculator/"
                    >
                      Mortgage Calculator
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<style>
.logo-name {
  font-size: 2rem;
  color: #292929;
  font-weight: normal;
}
</style>
