<script>
import { ref } from "vue";
import { chunk } from 'lodash'
import MemberApi from "@/http/member";

export default {
    name: "Members",

    async setup() {
        const members = ref([]);
        const memberChunks = ref([])

        members.value = await MemberApi.getAllResources();
        memberChunks.value = chunk(members.value, 3)


        return {
            memberChunks
        };
    },
};
</script>
<template>
    <section class="page-title page-title-1 image-bg overlay">
        <div class="background-image-holder">
            <img
                alt="Background Image"
                class="background-image"
                src="@/index/assets/img/real-estate-team.jpg"
            />
        </div>
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1 class="uppercase mb0">Meet The Team</h1>
                </div>
            </div>
        </div>
    </section>
    <section class="projects bg-secondary">
        <div class="container-fluid">
            <section>
                <div class="container">
                    <div v-for="(members, idx) in memberChunks" :key="idx" class="row mb48">
                        <div v-for="member in members" :key="member.id" class="col-md-4 col-sm-6">
                            <div class="image-tile outer-title">
                                <img alt="Pic" :src="member.fileurl">
                                <div class="title mb16">
                                    <h5 class="uppercase mb0">{{ member.name }}</h5>
                                    <h6>{{ member.title }}</h6>
                                </div>
                                <p v-if="member.bio" class="mb0">
                                    {{ member.bio }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--end of row-->
                </div>
                <!--end of container-->
            </section>
        </div>
        <!--end of row-->
    </section>
</template>
