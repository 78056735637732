import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home/Home.vue';
import Listings from '../views/Listings.vue';
import Posts from '../views/Posts.vue';
import SinglePost from '../views/Post.vue';
// import Collections from '../views/Collections.vue';
import MarketTrends from '../views/MarketTrends.vue';
import Members from '../views/Members.vue';
import Testimonials from '../views/Testimonials.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/listings',
    name: 'Listings',
    component: Listings,
  },
  // {
  //   path: '/collections',
  //   name: 'Collections',
  //   component: Collections,
  // },
  {
    path: '/market-trends',
    name: 'MarketTrends',
    component: MarketTrends,
  },
  {
    path: '/contact-us',
    name: 'ContactMe',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactMe.vue'),
  },
  {
    path: '/about-kristen',
    name: 'AboutKristen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutKristen.vue'),
  },
  {
    path: '/about-cindy',
    name: 'AboutCindy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutCindy.vue'),
  },
  {
    path: '/blog',
    name: 'Posts',
    component: Posts,
  },
  {
    path: '/blog/:slug',
    name: 'SinglePost',
    props: true,
    component: SinglePost,
  },
  {
    path: '/meet-team',
    name: 'MeetTeam',
    component: Members
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: Testimonials
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
});

router.afterEach((to, from) => {
  setTimeout(() => {
    $('.background-image-holder').each(function () {
      const imgSrc = $(this).children('img').attr('src');
      $(this).css('background', `url("${imgSrc}")`);
      $(this).children('img').hide();
      $(this).css('background-position', 'initial');
      $(this).addClass('fadeIn');
    });
  }, 300);
});

export default router;
