<template>
    <label v-show="showLabel" :for="$attrs.id">{{ labelText || label }}</label>
    <textarea v-bind="$attrs" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"></textarea>
</template>
<script>
export default {
  name: 'BaseInput',

  props: {
    label: {
      type: String,
      default: null,
    },

    labelText: {
      type: String,
      default: null,
    },

    modelValue: {
      type: [String, Number],
      default: '',
    },
  },

  computed: {
    showLabel() {
      return this.label || this.labelText;
    },
  },
};
</script>
