<template>
    <teleport to="head">
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
            href="https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap"
            rel="stylesheet"
        />
    </teleport>
    <teleport to="body">
        <BaseHeader></BaseHeader>
        <div class="main-container">
            <router-view v-slot="{ Component }">
              <suspense>
                  <component :is="Component"></component>
                  <template #fallback>
                      <div>Loading...</div>
                  </template>
              </suspense>
            </router-view>
            <BaseFooter></BaseFooter>
        </div>
    </teleport>
</template>
<script>
import BaseFooter from "@/index/components/Footer.vue";
import BaseHeader from "@/index/components/Header.vue";

export default {
    name: "Root",

    components: {
        BaseHeader,
        BaseFooter,
    },
};
</script>
<style lang="css">
@import "./assets/css/themify-icons.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "./assets/css/flexslider.css";
@import "./assets/css/theme.css";
@import "./assets/css/custom.css";
</style>
