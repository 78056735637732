<script>
export default {
    name: "ImageBlock",

    props: {
        imgName: {
            type: String,
            required: true,
            default: () => "",
        },
        altText: {
            type: String,
            required: true,
            default: "",
        },
        orientation: {
            type: String,
            required: true,
            default: () => "left",
        },

        withBackground: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        backgroundImage() {
            return require(`@/index/assets/img/${this.imgName}`);
        },
        getBackgroundStyle() {
            return {
                backgroundImage: `url(${this.backgroundImage})`,
                "background-position": "initial",
            };
        },
    },
};
</script>
<template>
    <section class="row">
        <template v-if="orientation === 'left'">
            <div class="col-md-4">
                <div
                    v-if="withBackground"
                    ref="imgHolder"
                    class="background-image-holder fadeIn"
                    :style="getBackgroundStyle"
                >
                    <img
                        :alt="altText"
                        class="background-image"
                        :src="backgroundImage"
                    />
                </div>
                <img
                    v-else
                    :alt="altText"
                    class="background-image"
                    :src="backgroundImage"
                />
            </div>
            <div class="col-md-8 content"><slot></slot></div>
        </template>
        <template v-else>
			<div class="col-md-8 content"><slot></slot></div>
            <div class="col-md-4">
                <div
                    v-if="withBackground"
                    ref="imgHolder"
                    class="background-image-holder fadeIn"
                    :style="getBackgroundStyle"
                >
                    <img
                        :alt="altText"
                        class="background-image"
                        :src="backgroundImage"
                    />
                </div>
                <img
                    v-else
                    :alt="altText"
                    class="background-image"
                    :src="backgroundImage"
                />
            </div>
        </template>
    </section>
</template>
<style>
.image-square .image {
    height: auto;
}
</style>
