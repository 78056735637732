<script>
export default {
    name: "Footer",

    data() {
        return {
            email: "",
            success: "",
            error: ""
        };
    },

    methods: {
        postForm(payload) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url: "/api/contactform/subscribe",
                    method: "POST",
                    contetType: "application/json",
                    data: payload
                })
                    .done(resolve)
                    .fail(reject);
            });
        },

        submitForm() {
            this.success = "";
            this.error = "";

            const payload = { email: this.email };
            this.postForm(payload)
                .then(() => (this.success = "Message successfully sent."))
                .catch(
                    () =>
                        (this.error =
                            "There was a problem processing your request. Please try again later")
                );
        },
    },
};
</script>
<template>
    <footer class="footer-1 bg-dark">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3 col-md-offset-1 col-sm-6">
                    <div class="widget">
                        <div class="logo-name mb32">
                            <img src="@/index/assets/img/smg-logo-white.png" alt="" height="150" style="margin-left: -15px">
                        </div>
                        <div class="mb32">
                            <img
                                height="20"
                                src="../assets/img/compass_logo.png"
                                alt=""
                            />
                        </div>
                        <div>
                            <small style="color: #c1c9d0"
                                >Compass is a real estate broker licensed by the
                                State of California and abides by Equal Housing
                                Opportunity laws. License Number 01527235. All
                                material presented herein is intended for
                                informational purposes only and is compiled from
                                sources deemed reliable but has not been
                                verified. Changes in price, condition, sale or
                                withdrawal may be made without notice. No
                                statement is made as to accuracy of any
                                description. All measurements and square footage
                                are approximate.</small
                            >
                        </div>
                    </div>
                </div>
                <div class="col-md-2 col-md-offset-1 col-sm-6">
                    <div class="widget">
                        <h5 class="footer-title">Contact Us</h5>
                        <hr />
                        <div>
                            <div class="social-list mb16">
                                <strong style="font-size: 14px">
                                    Kristen Stuecher&nbsp;
                                </strong>
                                <br>
                                <span
                                    >M:
                                    <a href="tel:415-948-4888">415-948-4888</a
                                    >&nbsp; | &nbsp;DRE#: 01768022</span
                                >
                            </div>
                            <div class="social-list">
                                <strong style="font-size: 14px">
                                    Cindy Manning&nbsp;
                                </strong>
                                <br>
                                <span
                                    >M:
                                    <a href="tel:415-385-1031">415-385-1031</a
                                    >&nbsp; | &nbsp;DRE#: 02086335</span
                                >
                            </div>
                            <br />
                            <span>
                                <a
                                    href="mailto:StuecherManningGroup@compass.com"
                                    >StuecherManningGroup@compass.com</a
                                >
                            </span>
                            <br />
                            <br />

                            <span> 891 Beach Street </span>
                            <br />
                            <span> San Francisco, CA 94109 </span>
                            <br />
                            <br />
                                                    <h6 class="footer-title">Social Media</h6>
                        <hr />
                        <ul class="social-list">
                            <li>
                                <a
                                    target="_blank"
                                    href="https://www.instagram.com/stuechermanninggroup/"
                                >
                                    <i class="ti-instagram mr-10"></i>
                                    <span>Instagram</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    href="https://www.facebook.com/stuechermanning"
                                >
                                    <i class="ti-facebook mr-10"></i>
                                    <span>Facebook</span>
                                </a>
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-md-offset-1 col-sm-6">
                    <div class="widget">
                        <h6 class="footer-title">Newsletter</h6>
                        <hr />
                        <div v-show="success.length === 0">
                            <p class="lead">
                                For exclusive news and market updates sign up
                                for our newsletter.
                            </p>
                            <form @submit.prevent="submitForm">
                                <div class="form-group">
                                    <input
                                        v-model="email"
                                        type="text"
                                        class="validate-required validate-email"
                                        placeholder="Email"
                                    />
                                </div>
                                <button class="btn btn-white btn-lg">
                                    Submit
                                </button>
                            </form>
                        </div>
                        <div v-show="success.length > 0" class="alert alert-success" role="alert">
                            {{ success }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <a class="btn btn-sm fade-half back-to-top inner-link" href="#top"
            >Top</a
        >
    </footer>
</template>
<style>
.social-list {
    color: white !important;
}

.footer-title {
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
    margin-bottom: 12px;
}
</style>
