<script>
import ImageBlock from "@/components/ImageBlock.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseTextarea from "@/components/BaseTextarea.vue";
import PostCarousel from "@/index/views/Home/PostCarousel.vue";
import PostApi from "@/http/post";
import TestimonialsApi from "@/http/testimonial";
import { shuffle } from "lodash"

export default {
  name: "Home",

  data() {
    return {
      posts: [],
      testimonials: [],
      selectedTestimonial: "",
      error: "",
      success: "",
      form: {
        name: {
          value: "",
          validate: (val) => val.length >= 4,
        },
        email: {
          value: "",
          validate: (val) =>
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              val
            ),
        },
        phone: {
          value: "",
          validate: () => true,
        },
        message: {
          value: "",
          validate: (val) => val.length > 5,
        },
      },
      slideImages: ["home-bg", "home-bg1", "home-bg2", "home-bg3"],
      stats: [
        "TOP 5% OF REALTORS IN THE US",
        "7 DAYS UNDER CITY AVERAGE ON THE MARKET",
        "TOP 50 REALTORS IN SF",
        "5.6% ABOVE ASKING PRICE ON AVERAGE",
        "10% OF ALL PROCEEDS GO TO CHARITY",
      ],
    };
  },

  components: {
    ImageBlock,
    BaseInput,
    BaseTextarea,
    PostCarousel,
  },

  mounted() {
    setTimeout(() => this.initializeSliders(), 500);
  },

  beforeRouteEnter(to, from, next) {
    let posts, testimonials;
    PostApi.getAllResources({ featured: true })
      .then((response) => (posts = response))
      .then(() => TestimonialsApi.getAllResources({ random: true }))
      .then((response) => (testimonials = response))
      .then(() => {
        next((vm) => {
          vm.posts = posts;
          let cindyTestimonials = testimonials.filter(t => t.text.includes('Cindy')).slice(0, 5)
          let kristenTestimonials = testimonials.filter(t => t.text.includes('Kristen')).slice(0, 5)
          
          vm.testimonials = shuffle([...cindyTestimonials, ...kristenTestimonials]);
        });
      });
  },

  methods: {
    selectTestimonial(text) {
      this.selectedTestimonial = text;
    },

    initializeSliders() {
      if (
        $(
          ".slider-all-controls, .slider-paging-controls, .slider-arrow-controls, .slider-thumb-controls, .logo-carousel"
        ).length
      ) {
        $(".slider-all-controls").flexslider({
          start(slider) {
            if (
              slider
                .find(".slides li:first-child")
                .find(".fs-vid-background video").length
            ) {
              slider
                .find(".slides li:first-child")
                .find(".fs-vid-background video")
                .get(0)
                .play();
            }
          },
          after(slider) {
            if (slider.find(".fs-vid-background video").length) {
              if (
                slider
                  .find("li:not(.flex-active-slide)")
                  .find(".fs-vid-background video").length
              ) {
                slider
                  .find("li:not(.flex-active-slide)")
                  .find(".fs-vid-background video")
                  .get(0)
                  .pause();
              }
              if (
                slider
                  .find(".flex-active-slide")
                  .find(".fs-vid-background video").length
              ) {
                slider
                  .find(".flex-active-slide")
                  .find(".fs-vid-background video")
                  .get(0)
                  .play();
              }
            }
          },
        });
        $(".slider-paging-controls").flexslider({
          animation: "slide",
          directionNav: false,
        });
        $(".slider-arrow-controls").flexslider({
          controlNav: false,
        });
        $(".slider-thumb-controls .slides li").each(function () {
          const imgSrc = $(this).find("img").attr("src");
          $(this).attr("data-thumb", imgSrc);
        });
        $(".slider-thumb-controls").flexslider({
          animation: "slide",
          controlNav: "thumbnails",
          directionNav: true,
        });
        $(".logo-carousel").flexslider({
          minItems: 1,
          maxItems: 4,
          move: 1,
          itemWidth: 200,
          itemMargin: 0,
          animation: "slide",
          slideshow: true,
          slideshowSpeed: 3000,
          directionNav: false,
          controlNav: false,
        });
      }
    },

    postForm(payload) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: "/api/contactform",
          method: "POST",
          contetType: "application/json",
          data: payload
        })
          .done(resolve)
          .fail(reject);
      });
    },

    resetForm() {
      Object.keys(this.form).forEach(k => this.form[k].value = "")
    },

    submitForm() {
      this.success = "";
      this.error = "";

      if (this.validate()) {
        const payload = {
          email: this.form.email.value,
          name: this.form.name.value,
          phone: this.form.phone.value,
          message: this.form.message.value,
        };
        this.postForm(payload)
          .then(() => {
            this.success = "Message successfully sent.";
          })
          .then(() => this.resetForm())
          .catch(() => {
            this.error =
              "There was a problem processing your request. Please try again later";
          });
      } else {
        this.error = "Please fill out all required fields.";
      }
    },

    validate() {
      return Object.keys(this.form).every((key) =>
        this.form[key].validate(this.form[key].value)
      );
    },
  },
};
</script>
<template>
  <teleport to="body">
    <div
      class="modal fade"
      id="testimonial-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="testimonial-modalLabel"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">{{ selectedTestimonial }}</div>
        </div>
      </div>
    </div>
  </teleport>
  <div id="home">
    <section
      class="
        cover
        fullscreen
        image-slider
        slider-all-controls
        controls-inside
        parallax
      "
    >
      <ul class="slides">
        <li
          v-for="(img, idx) in slideImages"
          class="overlay image-bg"
          :key="idx"
        >
          <div class="background-image-holder">
            <img
              alt="image"
              class="background-image"
              :src="require(`@/index/assets/img/${img}.jpg`)"
            />
          </div>
          <div class="container v-align-transform">
            <div class="row">
              <div class="col-sm-10 col-sm-offset-1 text-center">
                <img
                  src="@/index/assets/img/symbol-logo.png"
                  height="240"
                  alt=""
                />
                <h1 class="mb40 mb-xs-16 uppercase">
                  Stuecher Manning Group
                  <br />
                </h1>
                <h4 class="uppercase mb16">
                  Representing Buyers & Sellers of Distinguished San Francisco
                  Properties
                </h4>
                <h5 class="uppercase mb40">
                  Exceeding your expectations is our Top Priority
                </h5>
                <router-link to="/contact-us" class="btn btn-white btn-lg"
                  >Contact Us</router-link
                >
                <router-link to="/listings" class="btn btn-white btn-lg"
                  >Featured Listings</router-link
                >
              </div>
            </div>
            <!--end of row-->
          </div>
          <!--end of container-->
        </li>
      </ul>
    </section>

    <section class="pb120 pt-xs-40 pb-xs-40">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 col-md-offset-1">
            <div class="row mb32">
              <div class="col-md-12 text-center">
                <h3>MEET THE STUECHER MANNING GROUP</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <img
                  src="@/index/assets/img/about-smg.jpeg"
                  alt="About the Stuecher Manning Group"
                  style="display: block; margin: auto; width: 80%"
                />
              </div>
              <div class="col-md-6">
                <p class="lead">
                  The Stuecher Manning Group is a dynamic real estate collective
                  known for delivering a smart, strategic approach to Bay Area
                  buying and selling. Led by Kristen Stuecher and Cindy Manning,
                  and backed by talented marketers and coordinators, the team is
                  proactive and solutions-focused in everything they do. They
                  wield Compass' industry-leading tools and technology to
                  optimize every transaction, but they are also profoundly
                  empathetic advocates interested in understanding their
                  clients' unique goals and exceeding their expectations. By
                  prioritizing the client experience above all else, the group
                  consistently builds long-term loyalty and lasting
                  relationships with customers, all while having fun and
                  creating seamless, stress-free and successful outcomes.
                </p>
                <p class="lead">
                  A 17-year Bay Area real estate veteran, Kristen has more than
                  $400 million in closed transactions to her credit. She
                  consistently ranks among the top agents in the city. By
                  working with everyone from first-time buyers to major
                  developers and builders, Kristen has assembled a skill set and
                  comprehensive knowledge base that lets every client know their
                  real estate needs are in the best possible hands.
                </p>
                <p class="lead">
                  Cindy has lived in San Francisco for more than two decades,
                  which gives her an intuitive understanding of local real
                  estate inventory and market trends. Cindy came to real estate
                  after a successful marketing career specializing in strategic
                  planning, branding and account management, which has proven to
                  be a great asset to her real estate clients. Her satisfied
                  clients rave about her thorough attention to detail and
                  tenacity, and they commend her ability to be present, prepared
                  and supportive through every step of the process.
                </p>
                <p class="lead">
                  As the Stuecher Manning Group, this powerhouse pair leverages
                  their combined strengths to deliver responsive, high-touch
                  service that's both professional and personable. They offer
                  extensive San Francisco expertise and a vast network of
                  professionals and partners willing to help clients locally and
                  in major markets across the country. No matter your Bay Area
                  real estate goals, Kristen and Cindy are the collaborative and
                  creative partnership you need in your corner.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 col-md-offset-1">
          <hr style="margin: 0"/>
        </div>
      </div>
    </div>

    <section class="pb120 pt-xs-40 pb-xs-40">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-10 col-md-offset-1 text-center">
            <h3>OUR PERFORMANCE</h3>

            <p class="lead">
              Buying or selling your home is an exciting time - especially in
              the Bay Area. It is important to have a real estate team with a
              strong track record of repeat buyers and sellers. We have built
              our reputations as top real estate agents in San Francisco by
              partnering with you at each step, from listing a property to
              searching for your dream home to closing the transaction. Customer
              service and market knowledge are what drives our team.
            </p>
            <p class="lead">
              The market in San Francisco and the Bay Area is one of the most
              desired. In working with sellers, we strive to prepare, price, and
              market each home for optimization. Our closing rate is something
              we are proud of. For buyers, our team is knowledgeable about the
              things that matter most: top school districts, areas with the best
              resale estimates, creating the best offer for you, and all of the
              hidden gems in the nooks and crannies of buying a home.
            </p>
            <p class="lead">
              Our track record speaks for itself. We are continuously among the
              top real estate agents in San Francisco. Testimonials from our
              clients speak for themselves. Even in this overhanging market,
              over 86% of our buyers get into contract with their first offer.
              For sellers, our average list-to-sale price is 5.6% higher than
              the city average. We work diligently to implement the best
              strategies to achieve your goals.
            </p>

            <div class="row">
              <div class="col-md-12">
                <div class="circle_wrapper mt32">
                  <div
                    class="circle_container"
                    v-for="(stat, idx) in stats"
                    :key="idx"
                  >
                    <div class="circle_main">
                      <div class="circle_text_container">
                        <div class="circle_text">
                          {{ stat }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 col-md-offset-1">
          <hr class="mb64" />
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 col-md-offset-1">
          <ImageBlock
            img-name="block-img-1.jpg"
            orientation="left"
            class="pt64"
          >
            <h3 class="uppercase">Ready to sell or buy?</h3>
            <p class="mb0 lead">
              When you make a decision to work with us, you're not just getting
              two agents who utilize the best tools and technology to help you
              with your real estate transaction, you're getting transparency and
              instant communication throughout the entire process, so you can
              relax knowing every detail is taken care of.
            </p>

            <router-link to="/contact-us" class="btn bg-dark uppercase mt32">
              Get In Touch
            </router-link>
          </ImageBlock>
          <ImageBlock img-name="block-img-2.jpg" orientation="right">
            <h3 class="uppercase">MAXIMIZE THE VALUE OF YOUR HOME</h3>
            <p class="mb0 lead">
              Compass Concierge is a proprietary program which offers our
              clients a no-fee, zero interest loan to cover the costs associated
              with getting your property ready for market. We’ll work with you
              to determine how we can maximize the value of your home with
              improvements like painting, flooring, staging and even kitchen and
              bathroom upgrades and then manage the process with our preferred
              team of vendors. When your home sells, the funds spent will then
              be deducted from the proceeds of your sale.
            </p>
            <a
              target="_blank"
              href="https://www.compass.com/concierge/kristen-stuecher/"
              class="btn bg-dark uppercase mt32"
            >
              Learn More
            </a>
          </ImageBlock>
        </div>
      </div>
    </div>

    <section class="bg-secondary pb16">
      <div class="container-fluid">
        <div class="row mb64 mb-xs-24">
          <div class="col-md-10 col-md-offset-1 col-sm-12 text-center">
            <h3>STUECHER MANNING GROUP IN THE NEWS</h3>
          </div>
        </div>

        <PostCarousel v-if="posts.length" :posts="posts"></PostCarousel>
      </div>
    </section>

    <section class="bg-secondary pb32 pt8">
      <div class="container-fluid">
        <div class="row">
          <div class="news-logos">
            <div>
              <a
                href="https://www.sfgate.com/realestate/article/A-long-search-bears-fruit-San-Francisco-man-3199249.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="@/index/assets/img/news-logos/download.png" alt="" />
              </a>
            </div>
            <div>
              <a
                href="https://www.sfchronicle.com/bayarea/article/Live-work-lofts-blend-form-function-14984399.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="@/index/assets/img/news-logos/download-1.png"
                  alt=""
                />
              </a>
            </div>
            <div>
              <a
                href="https://sf.curbed.com/2017/11/15/16658544/condo-inside-historic-clock-tower-building-seeks-1-3-million"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="@/index/assets/img/news-logos/download-2.png"
                  alt=""
                />
              </a>
            </div>
            <div>
              <a
                href="https://www.elledecor.com/design-decorate/house-interiors/a19577935/mid-century-modern-homes-san-francisco-real-estate/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="@/index/assets/img/news-logos/download-3.png"
                  alt=""
                />
              </a>
            </div>
            <div>
              <a
                href="https://www.homelight.com/blog/top-real-estate-agents-in-san-francisco/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="@/index/assets/img/news-logos/download-4.png"
                  alt=""
                />
              </a>
            </div>
            <div>
              <a
                href="https://www.bizjournals.com/sanfrancisco/news/2019/05/22/renovated-church-dolores-st-light-house-townhouse.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="@/index/assets/img/news-logos/download-5.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="image-bg overlay pt120 pb120 pt-xs-40 pb-xs-40 testimonials"
    >
      <div class="background-image-holder blur-image">
        <img
          alt="image"
          class="background-image"
          src="@/index/assets/img/meet-kristen.jpg"
        />
      </div>
      <div class="container">
        <div>
          <div class="row mb16 mb-xs-0">
            <div class="col-sm-12 text-center">
              <h3>CLIENT EXPERIENCES & TESTIMONIALS</h3>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1">
              <div
                class="text-slider slider-arrow-controls text-center relative"
              >
                <ul class="slides">
                  <li v-for="({ text }, idx) in testimonials" :key="idx">
                    <p class="lead">
                      "{{ text.slice(0, 500) }}
                      <a
                        v-if="text.length > 500"
                        @click="selectTestimonial(text)"
                        href="#"
                        data-toggle="modal"
                        data-target="#testimonial-modal"
                        >...Read More</a
                      >"
                    </p>
                    <div class="quote-author">
                      <h6 class="uppercase mb0 stars">★★★★★</h6>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-sm-6 col-md-5">
            <h4 class="uppercase">Get In Touch</h4>
                    <p class="lead">
                        We love where we live and you should too! Please get in
                        touch with us so we can help you reach your real estate
                        dreams!
                    </p>
                    <hr />
                    <p>
                      <strong style="font-size: 14px"> Kristen Stuecher&nbsp; </strong>
                        <br>
                        <span
                            >M:
                            <a href="tel:415-948-4888">415-948-4888</a>&nbsp; |
                            &nbsp;DRE#: 01768022</span
                        >
                      <br>
                      <br>
                        <strong style="font-size: 14px"> Cindy Manning&nbsp; </strong>
                        <br>
                        <span
                            >M:
                            <a href="tel:415-385-1031">415-385-1031</a>&nbsp; |
                            &nbsp;DRE#: 02086335</span
                        >
                        
                    </p>
                    <hr />
                    <p>
                        <span>
                            <a href="mailto:StuecherManningGroup@compass.com"
                                >StuecherManningGroup@compass.com</a
                            >
                        </span>
                    </p>
                    <hr />
                    <p>
                        891 Beach Street
                        <br />
                        San Francisco, CA 94109
                    </p>
          </div>
          <div class="col-sm-6 col-md-5 col-md-offset-1">
            <form @submit.prevent="submitForm" class="contact-form">
              <BaseInput
                v-model="form.name.value"
                type="text"
                class="validate-required"
                placeholder="Your Name *"
              />
              <BaseInput
                v-model="form.email.value"
                type="text"
                class="validate-required validate-email"
                placeholder="Email Address *"
              />
              <BaseInput
                v-model="form.phone.value"
                type="text"
                class="validate-required validate-email"
                placeholder="Phone"
              />
              <BaseTextarea
                v-model="form.message.value"
                class="validate-required mb0"
                rows="4"
                placeholder="Message *"
              ></BaseTextarea>
              <div class="mb16">
                <small>* Required fields</small>
              </div>

              <button class="btn bg-dark" type="submit">Send Message</button>
              <div v-show="success !== ''" class="alert alert-success">
                {{ success }}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style lang="scss">
#testimonial-modal {
  font-size: 14px;
  .close {
    margin-bottom: 0px;
  }

  .modal-header {
    padding-bottom: 0;
    border-bottom: none;
  }
}

#home {
  .profile-picture {
    max-height: 370px;
  }
  .bio-text {
    font-style: italic;
  }
  .stars {
    font-size: 20px;
  }

  .post-image-container {
    height: 400px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .blog-image {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blur-image {
    filter: blur(8px);
    -webkit-filter: blur(5px);
  }

  .testimonials {
    .lead {
      font-size: 18px;
    }
  }

  .circle_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .circle_container {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    padding: 0;
  }

  .circle_main {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #d9d9d9;
    margin: 0;
    padding: 0;
  }

  .circle_text_container {
    width: 70%;
    height: 70%;
    max-width: 70%;
    max-height: 70%;
    margin: 0;
    padding: 0;
    position: relative;
    left: 15%;
    top: 15%;
    transform-style: preserve-3d;
  }

  .circle_text {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 600;
    font-size: 18px;
    color: black;
  }
}

.carousel__prev,
.carousel__next {
  background-color: #ff000000 !important;
}

.carousel__prev {
  left: 3% !important;
}

.carousel__next {
  right: 3% !important;
}

.news-logos {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 17%;
  & > div {
    // width: 14.28571%;
    width: 140px; /* Or whatever */
    height: 100px; /* Or whatever */
    margin: auto;
    position: relative;
    & > a {
      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .circle_wrapper {
    flex-direction: column !important;

    .circle_container {
      margin-bottom: 20px !important;
    }
  }
}
</style>
