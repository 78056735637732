import http from '@/http';
import { onBeforeMount, ref } from 'vue';
import { keyBy } from 'lodash';

const getListingStatuses = () => http.get('/listingstatus');
const getListingTypes = () => http.get('/listingtype');
const getListingContractTypes = () => http.get('/listingcontracttype');
const getAmenities = () => http.get('/amenity');

//  Listing status
const useListingStatuses = () => {
	const listingStatuses = ref({});

	onBeforeMount(async () => {
		const res = await getListingStatuses();
		listingStatuses.value = keyBy(res, 'id');
	});

	return listingStatuses;
};

//  Listing types
const useListingTypes = () => {
	const listingTypes = ref({});

	onBeforeMount(async () => {
		const res = await getListingTypes();
		listingTypes.value = keyBy(res, 'id');
	});

	return listingTypes;
};

//  Listing amenities
const useAmenities = () => {
	const amenities = ref([]);

	onBeforeMount(async () => {
		const res = await getAmenities();
		amenities.value = res.sort((el1, el2) => {
			return el1.amenity < el2.amenity
				? -1
				: el1.amenity > el2.amenity
				? 1
				: 0;
		});
	});

	return amenities;
};

// Listing contract types
const useListingContractTypes = () => {
	const listingContractTypes = ref([]);

	onBeforeMount(async () => {
		const res = await getListingContractTypes();
		listingContractTypes.value = res.sort((el1, el2) => {
			return el1.contracttype < el2.contracttype
				? -1
				: el1.contracttype > el2.contracttype
				? 1
				: 0;
		});
	});

	return listingContractTypes;
};

// Listing neighborhood
const useListingNeighborhoods = () => {
	const listingNeighborhoods = ref([]);

	onBeforeMount(async () => {
		const res = await http.get('/neighborhood');
		listingNeighborhoods.value = res;
	});

	return listingNeighborhoods;
};

// Format currency
const formatCurrency = (val) => {
    return Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    }).format(val);
};

export {
	useListingTypes,
	useListingStatuses,
	useAmenities,
	useListingContractTypes,
	useListingNeighborhoods,
    formatCurrency
};
