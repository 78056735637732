<script>
import { reactive } from "vue";
import PostApi from "@/http/post";

export default {
    name: "SinglePost",

    props: {
      slug: {
        required: true,
        type: String
      }
    },

    async setup(props) {
      
        const slug = props.slug
        let post = reactive({
            title: "",
            body: "",
            datecreated: "",
            coverurl: "",
        });

        const getDate = (date = new Date()) => {
            const options = { dateStyle: "medium" };
            const formatter = new Intl.DateTimeFormat("en", options);

            return formatter.format(new Date(date));
        };

        post = await PostApi.getResource(slug);

        return {
            post,
            getDate,
        };
    },
};
</script>
<template>
    <section class="page-title page-title-4 bg-secondary">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="uppercase mb0">
                        {{ post.title }}
                    </h3>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-sm-10 col-sm-offset-1">
                    <div class="post-snippet mb64">
                        <img
                            class="mb24"
                            alt="Post Image"
                            :src="post.coverurl"
                        />
                        <div class="post-title">
                            <span class="label">{{ getDate(post.datecreated) }}</span>
                            <h4 class="inline-block">
                                {{ post.title }}
                            </h4>
                        </div>
                        <hr />
                        <div v-html="post.body"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
