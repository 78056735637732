<script>
import ImageBlock from "@/components/ImageBlock"
export default {
  name: 'MarketTrends',

    components: {
    ImageBlock,
    }
};
</script>
<template>
  <div id="market-trends">
    <section class="page-title page-title-1 image-bg overlay">
      <div class="background-image-holder">
        <img
          alt="Background Image"
          class="background-image"
          src="@/index/assets/img/market-trends.jpg"
        />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h1 class="uppercase mb0">Market Trends</h1>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container-fluid">
        <div class="row">
          
          <div class="col-md-10 col-md-offset-1">
                   <ImageBlock
            img-name="market-trends.jpeg"
            orientation="left"
            class="pt64"
          >
            <h3 class="uppercase">San Francisco Home Prices, Market Conditions & Trends</h3>
            <p class="mb0 lead">
              The most up-to-date and comprehensive analysis of San Francisco home prices, values, conditions and trends of the San Francisco Bay Area residential real estate market.
            </p>
            <a href="https://www.bayareamarketreports.com/" target="_blank" class="btn bg-dark uppercase mt32">Learn More</a>
          </ImageBlock>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
